import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

import styles from './article-preview.module.css'

export default ({ article }) => (
    <div className={styles.preview}>
      
        <h3 className={styles.previewTitle}>
        <Link to={`/investorRelations/${article.EncryptedId}`}>{article.Title}</Link>
        </h3>
        <small>{article.PublishDate.slice(0,-10)}</small>
        <div className="postPreview">
        <Link to={`/investorRelations/${article.EncryptedId}`}><img alt="" src={article.Images != null ? article.Images[0].UrlTo200x200Thumbnail : "#" } /></Link>
        <p>{article.Intro} </p> 
      </div>
    </div>
)
