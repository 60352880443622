import React from 'react'
//import { Link, graphql } from 'gatsby'
import get from 'lodash/get'
import Helmet from 'react-helmet'
//import styles from './blog.module.css'
import Layout from '../components/layout'
import ArticlePreview from '../components/ir-preview'


const IrPost = ({ data }) => {
    //console.log(data)
    const siteTitle = ('Kiwok - Investor Relations')
    return (
      <Layout>
        <Helmet title={siteTitle} />
        <>
        <div className="wrapperPage">
          <h2 className="section-headline">Investor relations</h2>
            {data.irPosts.Releases && data.irPosts.Releases.map(release => {
            //console.log(release)
            return(
              <li className="postPreviewList" key={release.EncryptedId}>
                <ArticlePreview article={release} />
              </li>
            )
          })}
        </div>
        </>
        </Layout>
        );
};

export default IrPost

export const pageQuery = graphql`
  query InvestorIndexQuery {
    site {
      siteMetadata {
        title
      }
    }
    irPosts {
        Releases {
          Title
          EncryptedId
          Intro
          PublishDate
          Images {
            UrlTo200x200Thumbnail
          }
        }
      }
  }
`
/*
export const pageQuery = graphql`
  query InvestorIndexQuery {
    site {
      siteMetadata {
        title
      }
    }
    irPosts {
        Releases {
          Title
          EncryptedId
          Intro
          PublishDate
          Images {
            UrlTo200x200Thumbnail 
          }
        }
      }
  }
`
*/